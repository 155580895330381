<template>
    <div>
      <b-modal
        id="modal-add-sport-league"
        cancel-variant="outline-secondary"
        :ok-title="$t('Add')"
        :cancel-title="$t('Close')"
        ok-variant="success"
        centered
        no-enforce-focus
        modal-class="modal-success"
        :title="$t('Add Sport League')"
        @ok="confirmAdd"
        @hidden="resetModal()"
      >
        <!-- form -->
        <validation-observer ref="addSportLeagueModal">
          <b-form method="POST" @submit.prevent="validationForm">
            <b-form-group label-for="name">
              <label class="mb-1"><span class="text-danger">*</span> {{ $t("Name ") }}</label>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="post.name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label-for="logo">
              <label for=""
                ><span class="text-danger">*</span> {{ $t("Logo") }}
                <span class="text-secondary">(Không > 10Mb)</span></label
              >
              <validation-provider
                #default="{ errors }"
                name="logo"
                rules="required|size:10240"
              >
                <b-form-file
                  v-model="post.logo"
                  input-id="logo"
                  accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              v-show="post.logo"
              variant="outline-danger"
              class="btn-sm btn-icon"
              @click="post.logo = null"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </template>
  
  <script>
  import {
    ValidationProvider,
    ValidationObserver,
  } from "vee-validate/dist/vee-validate.full.esm";
  import {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    VBModal,
    BInputGroupAppend,
    BFormTextarea,
    BBadge,
  } from "bootstrap-vue";
  import { ref, onUnmounted } from "@vue/composition-api";
  import vSelect from "vue-select";
  import Ripple from "vue-ripple-directive";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import store from "@/store";
  import router from "@/router";
  import Editor from "@tinymce/tinymce-vue";
  import API_KEY from "@/auth/apikey/apikeyeditor";
  export default {
    components: {
      BLink,
      BRow,
      BCol,
      BTabs,
      BTab,
      BForm,
      BFormGroup,
      BFormInput,
      BFormFile,
      BButton,
      BInputGroup,
      BInputGroupPrepend,
      BModal,
      BFormTextarea,
      BBadge,
      BInputGroupAppend,
      ValidationProvider,
      ValidationObserver,
      vSelect,
      Editor,
    },
    directives: {
      "b-modal": VBModal,
      Ripple,
    },
    props: {
      sportTypeId: {
        type: Array,
        default: null,
      },
    },
    data() {
      return {
        API_KEY,
        post: {
          name: "",
          logo: null,
        },
        init: {
          height: 600,
          content_style:
            this.$store.state.appConfig.layout.skin === "dark"
              ? "body { color: white; background-color:#293046 }"
              : "",
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "wordcount",
          ],
          toolbar:
            " undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent inden |image",
          skin:
            this.$store.state.appConfig.layout.skin === "dark"
              ? "oxide-dark"
              : "",
          image_title: true,
          automatic_uploads: true,
          file_picker_types: "image",
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
  
            input.onchange = function () {
              var file = this.files[0];
              var reader = new FileReader();
              reader.onload = function () {
                var id = "blobid" + new Date().getTime();
                var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                var base64 = reader.result.split(",")[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                cb(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
            };
            input.click();
          },
        },
      };
    },
    methods: {
      validationForm() {
        this.$refs.addSportLeagueModal.validate().then((success) => {
          if (success) {
            this.addPost(this.post);
          }
        });
      },
      async addPost(sportLeagueData) {
        await this.$store
          .dispatch("sports/addSportLeague", sportLeagueData)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code == "00") {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || "success",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.$emit("refetch-data");
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.$bvModal.hide("modal-add-sport-league");
                    this.resetModal();
                  }, "500");
                });
                this.$emit("refetch-data");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              }
            }
          });
      },
      resetModal() {
        this.post = {
          name: "",
          logo: null,
        };
      },
      confirmAdd(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.validationForm();
      },
    },
    setup() {
      return {};
    },
  };
  </script>
  